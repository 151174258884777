import { Button } from "@/components/ui/button";
import { Link } from "@remix-run/react";
import { ArrowRight } from "lucide-react";

export default function JobStruggleSection() {
    return (
        <section className="w-full">
            <div className="container px-4 md:px-6">
                <div className="flex flex-col items-center space-y-4 text-center">
                    <div className="space-y-2">
                        <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">
                            Struggling to Land That Job?
                        </h2>
                        <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed dark:text-gray-400">
                            Applying to multiple positions while constantly trying to tailor your
                            resume to job descriptions can be exhausting. Missing a single crucial
                            detail can mean losing out on the perfect job opportunity.
                        </p>
                    </div>
                </div>
                <div className="mx-auto grid max-w-5xl items-center gap-6 py-12 lg:grid-cols-2 lg:gap-12">
                    <div className="space-y-4">
                        <div className="inline-block rounded-lg bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 p-1">
                            <div className="rounded-lg bg-white p-4 dark:bg-gray-950">
                                <h3 className="text-xl font-bold">
                                    Stop Stressing Over Resume Rewrites
                                </h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    Instead of manually tailoring your resume for every job
                                    application, let our AI-powered CV tailoring tool handle the
                                    heavy lifting. Stand out from the crowd with a perfectly
                                    tailored resume for each position.
                                </p>
                            </div>
                        </div>
                        <div className="inline-block rounded-lg bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 p-1">
                            <div className="rounded-lg bg-white p-4 dark:bg-gray-950">
                                <h3 className="text-xl font-bold">Get Noticed and Hired</h3>
                                <p className="text-gray-500 dark:text-gray-400">
                                    Let us help you tailor your resume to job description
                                    requirements, ensuring you highlight the most relevant skills
                                    and experiences that will get you past the ATS and into the
                                    interview room.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col justify-center space-y-4">
                        <ul className="grid gap-4">
                            <li className="flex items-center">
                                <ArrowRight className="mr-2 h-5 w-5 text-pink-600" />
                                <span>AI-powered resume tailoring for each job application</span>
                            </li>
                            <li className="flex items-center">
                                <ArrowRight className="mr-2 h-5 w-5 text-purple-700" />
                                <span>Save time and reduce stress in your job search</span>
                            </li>
                            <li className="flex items-center">
                                <ArrowRight className="mr-2 h-5 w-5 text-blue-700" />
                                <span>
                                    Create ATS-friendly resumes through intelligent CV tailoring
                                </span>
                            </li>
                        </ul>
                        <Button
                            asChild
                            className="w-full sm:w-auto bg-gradient-to-br from-pink-600 via-purple-700 to-blue-700 text-white"
                        >
                            <Link to="/auth/signup" className="flex items-center gap-2">
                                Start Tailoring Your Resume Now
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
}
